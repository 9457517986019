//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FadeTransition } from "vue2-transitions";
import { plural } from "@/utils/numbers";
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      default: "add",
      validate: v => ["add", "remove", "update, multiple-approve"].includes(v)
    }
  },
  data() {
    return {
      input: "",
      newItems: this.items
    };
  },
  computed: {
    compText() {
      const len = this.items.length;
      return plural(len, ["已选择 {v} 类别列表排名", "已选择 {v} 项", "已选择 {v} 个项目"]);
    }
  },
  methods: {
    deselectAll() {
      this.$emit("update:items", []);
    },
    updateRatio() {
      this.$emit("action1");
    }
  },
  components: {
    FadeTransition
  }
};