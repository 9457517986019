//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideYUpTransition } from "vue2-transitions";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import { VPopover as Popover } from "v-tooltip";
import { dateRangeFromDate, dateRangeBoundaries, parseDate } from "@/utils/dates";
export * from "@/utils/dates";
dayjs.extend(customParseFormat, isoWeek);
window.dayjs = dayjs;
const FORMAT = "YYYY-MM-DD";
const INTERNAL_FORMAT = "DD/MM/YYYY";
export default {
  props: {
    value: {
      type: Array,
      default: undefined
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    fullWidthButton: {
      type: Boolean,
      default: false
    },
    buttonAlign: {
      type: String,
      default: "center"
    },
    boundaries: {
      type: Array,
      default() {
        return [undefined, undefined];
      }
    },
    absoluteTitle: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      validator(value) {
        return ["week", "month"].includes(value);
      }
    }
  },
  data() {
    return {
      // picker date
      date: [],
      // input date
      inputDate: ["", ""],
      defaultDate: [],
      showCalendar: false,
      lang: {
        formatLocale: {
          months: ["一月", "二月", "行进", "四月", "可能", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
          monthsShort: ["扬", "二月", "行进", "四月", "可能", "六月", "七月", "八月", "九月", "十月", "但是我", "十二月"],
          weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          firstDayOfWeek: 1
        }
      },
      scrollableParents: []
    };
  },
  mounted() {
    const scrollable = [];
    let node = this.$el;
    while (node) {
      if (node.classList && node.classList.contains("ps-container") && node.__vue__) {
        scrollable.push(node);
      }
      node = node.parentNode;
    }
    scrollable.forEach(node => {
      node.__vue__.$on("ps-scroll-x", this.forceUpdatePosition);
      node.__vue__.$on("ps-scroll-y", this.forceUpdatePosition);
    });
    this.scrollableParents = scrollable;
  },
  beforeDestroy() {
    this.scrollableParents.forEach(node => {
      node.__vue__.$off("ps-scroll-x", this.forceUpdatePosition);
      node.__vue__.$off("ps-scroll-y", this.forceUpdatePosition);
    });
  },
  methods: {
    isDateDisabledForPicker(dateNative) {
      const date = dayjs(dateNative);
      if (this.boundariesDates[0] && date < this.boundariesDates[0]) {
        return true;
      }
      if (this.boundariesDates[1] && date > this.boundariesDates[1]) {
        return true;
      }
      return false;
    },
    toStep(v) {
      let date1 = parseDate(v[0], INTERNAL_FORMAT);
      let date2 = parseDate(v[1], INTERNAL_FORMAT);
      if (this.step === "week") {
        date1 = date1.startOf("week").add(1, "day");
        date2 = date2.endOf("week").add(1, "day");
      }
      if (this.step === "month") {
        date1 = date1.startOf("month");
        date2 = date2.endOf("month");
      }
      return [date1.format(INTERNAL_FORMAT), date2.format(INTERNAL_FORMAT)];
    },
    onPickerInput(v) {
      if (this.step) {
        v = this.toStep(v);
      }
      const boundaries = this.boundariesDates.map(i => i ? i.format(INTERNAL_FORMAT) : i);
      let range = dateRangeBoundaries(v, boundaries, INTERNAL_FORMAT);
      this.date = [...range];
      this.inputDate = [...range];
    },
    onInput(v, index) {
      this.inputDate[index] = v;
      if (v === "" && this.allowEmpty) {
        this.$set(this.date, index, v);
      }
      let date = dayjs(v, INTERNAL_FORMAT);
      if (!date.isValid()) {
        return;
      }
      if (this.boundariesDates[0] && date < this.boundariesDates[0]) {
        date = this.boundariesDates[0];
      }
      if (this.boundariesDates[1] && date > this.boundariesDates[1]) {
        date = this.boundariesDates[1];
      }
      if (index === 0) {
        const dateEnd = dayjs(this.date[1], INTERNAL_FORMAT);
        if (date > dateEnd) {
          date = dateEnd;
        }
      } else {
        const dateStart = dayjs(this.date[0], INTERNAL_FORMAT);
        if (date < dateStart) {
          date = dateStart;
        }
      }
      this.$set(this.date, index, date.format(INTERNAL_FORMAT));
    },
    onInputBlur() {
      this.inputDate = [...this.date];
    },
    forceUpdatePosition() {
      var _this$$refs$popover;
      const popper = (_this$$refs$popover = this.$refs.popover) === null || _this$$refs$popover === void 0 ? void 0 : _this$$refs$popover.popperInstance;
      if (popper) {
        popper.scheduleUpdate();
      }
    },
    openCalendar() {
      this.showCalendar = true;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    toogleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    change() {
      this.valueComputed = this.date;
      this.closeCalendar();
    },
    resetChange() {
      this.onPickerInput(this.boundariesDates.map(i => i.format(INTERNAL_FORMAT)));
    },
    setDateRange(days) {
      var _this$boundariesDates;
      const lastAvailableDate = (((_this$boundariesDates = this.boundariesDates) === null || _this$boundariesDates === void 0 ? void 0 : _this$boundariesDates[1]) || dayjs()).format(INTERNAL_FORMAT);
      this.onPickerInput(dateRangeFromDate(lastAvailableDate, days, INTERNAL_FORMAT));
    }
  },
  created() {
    if (this.allowEmpty) {
      this.defaultDate = ["", ""];
    } else {
      this.defaultDate = [...this.valueComputed];
    }
  },
  watch: {
    valueComputed: {
      immediate: true,
      handler(v) {
        this.date = [...v];
        this.inputDate = [...v];
      }
    }
  },
  computed: {
    boundariesDates() {
      return this.boundaries.map(i => i ? dayjs(i, FORMAT) : i);
    },
    valueComputed: {
      get() {
        if (!this.value) {
          return ["", ""];
        }
        return this.value.map(item => {
          if (!item) {
            return item;
          }
          return dayjs(item, FORMAT).format(INTERNAL_FORMAT);
        });
      },
      set(v) {
        const outputValue = v.map((item, index) => {
          if (!item) {
            if (this.allowEmpty) {
              return item;
            } else {
              return dayjs(this.defaultDate[index], INTERNAL_FORMAT).format(FORMAT);
            }
          }
          return dayjs(item, INTERNAL_FORMAT).format(FORMAT);
        });
        if (!outputValue[0] && !outputValue[1]) {
          this.$emit("input", undefined);
          return;
        }
        this.$emit("input", outputValue);
      }
    },
    calendarDateFrom() {
      var _this$valueComputed$;
      return (_this$valueComputed$ = this.valueComputed[0]) === null || _this$valueComputed$ === void 0 ? void 0 : _this$valueComputed$.replace("/", ".").replace("/20", ".");
    },
    calendarDateTo() {
      var _this$valueComputed$2;
      return (_this$valueComputed$2 = this.valueComputed[1]) === null || _this$valueComputed$2 === void 0 ? void 0 : _this$valueComputed$2.replace("/", ".").replace("/20", ".");
    }
  },
  components: {
    DatePicker,
    SlideYUpTransition,
    Popover
  }
};