//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Checkbox from "@/components/Checkbox.vue";
import LoadingIndicator from "@/components/Loading/Indicator";
import { dateFormat, dateTimeFormat, dateTimeLocalFormat } from "@/utils/dates";
import AppSelect from "@/components/AppSelect.vue";
export default {
  props: ["columns", "items", "sort", "selected", "loading", "error", "rawResponse", "itemId", "selectable", "stickyColumns", "rowClickable", "noData"],
  data() {
    return {
      scrollSettings: {
        suppressScrollX: false,
        suppressScrollY: false,
        wheelPropagation: true
      },
      select_options: [{
        id: 1,
        title: 20
      }, {
        id: 2,
        title: 50
      }, {
        id: 3,
        title: 100
      }],
      select_model: null,
      select_all: false,
      page: 1,
      sortedBy: null,
      sortedAsc: true,
      loadedItems: null,
      showGradient: false
    };
  },
  created() {
    this.select_model = this.select_options[0];
  },
  methods: {
    getTdStyle(column) {
      let stickyColumns = this.stickyColumns;
      if (this.selectable) {
        stickyColumns++;
      }
      let index;
      let left = 0;
      if (column) {
        let columnIndex = this.visibleColumns.indexOf(column);
        index = columnIndex;
        if (index === -1) {
          return {};
        }
        if (this.selectable) {
          index++;
        }
        left = this.visibleColumns.slice(0, columnIndex).reduce((acc, col) => {
          return acc + col.width;
        }, this.selectable ? 50 : 0);
      } else {
        index = 0;
      }
      if (index >= stickyColumns) {
        return {};
      }
      return {
        position: "sticky",
        background: "#fff",
        "z-index": 1,
        left: left + "px"
      };
    },
    getActionsItems(column) {
      return column.actions.map((action, i) => {
        return {
          id: i + 1,
          title: action.title,
          action: action.action
        };
      });
    },
    onActionSelect(item, action) {
      action.action(item, {
        refresh: () => this.refresh()
      });
    },
    refresh() {
      this.$emit("refresh");
    },
    formatDate(v) {
      return dateFormat(v);
    },
    formatDateTime(v) {
      return dateTimeFormat(v);
    },
    formatDateTimeLocal(v) {
      return dateTimeLocalFormat(v);
    },
    isChecked(item) {
      return !!this.selected.find(i => i[this.itemId] === item[this.itemId]);
    },
    onCheckbox(v, item) {
      let newValue = this.selected.filter(i => i[this.itemId] !== item[this.itemId]);
      if (v) {
        newValue.push(item);
      }
      this.$emit("update:selected", newValue);
    },
    setSortBy(col) {
      if (col.type === "actions") {
        return;
      }
      if (this.sortedBy == col.name) this.sortedAsc = !this.sortedAsc;else this.sortedAsc = false;
      this.sortedBy = col.name;
      this.$emit("update:sort", {
        column: this.sortedBy,
        direction: this.sortedAsc ? "asc" : "desc"
      });
      this.page = 1;
    },
    isNum(i) {
      return typeof i === "number" || i.slice(-1) === "₽";
    },
    handleScroll(e) {
      this.showGradient = e.target.scrollLeft > 0;
    }
  },
  computed: {
    visibleColumns() {
      return this.columns.filter(i => !!i.show);
    },
    selectAll: {
      get() {
        return this.items && this.selected.length && !this.items.find(item => !this.selected.find(selected => selected[this.itemId] === item[this.itemId]));
      },
      set(v) {
        if (!this.items) {
          return;
        }
        const filtered = this.selected.filter(selected => !this.items.find(item => item[this.itemId] === selected[this.itemId]));
        this.$emit("update:selected", v ? [...filtered, ...this.items] : filtered);
      }
    },
    tableHead() {
      if (this.visibleColumns) {
        let lastFixed = null;
        const columns = this.visibleColumns.map((i, index) => {
          const style = this.getTdStyle(i);
          if (style.position === "sticky") {
            lastFixed = index;
          }
          return {
            ...i,
            style
          };
        });
        if (lastFixed !== null) {
          columns[lastFixed] = {
            ...columns[lastFixed],
            lastFixed: true
          };
        }
        return columns;
      }
      return [];
    },
    resFrom() {
      return this.page > 1 ? (this.page - 1) * this.select_model.title : 1;
    },
    resTo() {
      let res = this.resFrom + this.select_model.title;
      if (this.resFrom === 1) res -= 1;
      if (this.config && this.config.data) return res <= this.config.data.length ? res : this.config.data.length;
      return res;
    },
    allCount() {
      return this.config && this.config.data ? this.config.data.length : 0;
    },
    actualData() {
      return this.items;
      /* if (this.config && this.config.data) {
      let res = [...this.config.data]
      if (this.sortedBy) {
      if (this.sortedAsc)
      res.sort((a, b) => a[this.sortedBy].toString().localeCompare(b[this.sortedBy].toString()))
      else
      res.sort((a, b) => b[this.sortedBy].toString().localeCompare(a[this.sortedBy].toString()))
      }
      let n = (this.page-1) * this.select_model.title
      return res.slice(n, n + this.select_model.title)
      }
      return [] */
    },

    calcPag() {
      let res = [];
      const range = (start, stop, step = 1) => Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step);
      if (this.config && this.config.data) {
        if (this.numLastPage <= 5) return range(1, this.numLastPage + 1);
        if (this.numLastPage - this.page <= 3) {
          res.push(1);
          res.push(null);
          return [...res, ...range(this.page - 1, this.numLastPage + 1)];
        }
        if (this.page <= 3) {
          res.push(1);
          res.push(2);
          res.push(3);
          res.push(4);
          res.push(null);
          res.push(this.numLastPage);
        } else {
          res.push(1);
          res.push(null);
          res.push(this.page - 1);
          res.push(this.page);
          res.push(this.page + 1);
          res.push(null);
          res.push(this.numLastPage);
        }
      }
      return res;
    },
    numLastPage() {
      if (this.config && this.config.data) {
        return Math.ceil(this.config.data.length / this.select_model.title);
      }
      return 1;
    }
  },
  components: {
    Checkbox,
    LoadingIndicator,
    AppSelect
  },
  mounted() {},
  watch: {
    select_all(newVal) {
      this.actualData.forEach(el => {
        el.checked = newVal;
      });
    }
  }
};