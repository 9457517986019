//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideXRightTransition } from "vue2-transitions";
import Draggable from "vuedraggable";
import Checkbox from "@/components/Checkbox.vue";
import { disableBodyScroll, enableBodyScroll } from "@/utils";
import Scrollable from "@/components/Scrollable";
export default {
  model: {
    prop: "modelValue",
    event: "change"
  },
  data() {
    return {
      drag: false,
      scrollSettings: {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false
      },
      tableData: []
    };
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    modelValue: {
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: "设置表视图"
    },
    description: {
      type: String,
      default: "列显示设置及其顺序"
    }
  },
  methods: {
    fillData() {
      if (this.modelValue) {
        // iterate over columns instead of model value cause columns can be changed
        // input parser should be safe for unstable data structure
        this.tableData = this.columns.map(column => {
          let newItem = this.modelValue.find(i => i.name === column.name);
          if (column.noSettings) {
            newItem = false;
          }
          return {
            name: column.name,
            position: newItem ? newItem.position : column.position,
            show: newItem ? newItem.show : column.show,
            title: column.title,
            disabled: column.noSettings
          };
        }).sort((a, b) => a.position - b.position);
        return;
      }
      this.fillDefault();
    },
    fillDefault() {
      this.tableData = this.columns.map(item => {
        return {
          name: item.name,
          position: item.position,
          show: item.show,
          title: item.title,
          disabled: item.noSettings
        };
      }).sort((a, b) => a.position - b.position).filter(column => !!column.show);
    },
    close() {
      this.$emit("close", this.tableData);
    },
    reset() {
      this.fillDefault();
    },
    apply() {
      this.tableData.forEach((el, index) => {
        el.position = index + 1;
      });
      const newValue = this.tableData.map(i => {
        // output data format
        return {
          name: i.name,
          position: i.position,
          show: i.show
        };
      }).filter(i => {
        const column = this.columns.find(col => col.name === i.name);
        if (!column) {
          return false;
        }
        if (column.noSettings) {
          return false;
        }
        return i.position !== column.position || i.show !== column.show;
      });
      this.$emit("change", newValue);
      this.close();
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        disableBodyScroll();
      } else {
        enableBodyScroll();
      }
    },
    modelValue: {
      immediate: true,
      handler() {
        this.fillData();
      }
    },
    columns() {
      this.fillData();
    }
  },
  components: {
    SlideXRightTransition,
    Draggable,
    Checkbox,
    Scrollable
  }
};